import React from 'react'
import Layout from '../components/layout-v2';


const Page = () => (

  <Layout title="Merchandising & Planning">
    <section>

      <h3>Look at merchandising in a new light.</h3>
      <p>Don’t settle for an overly complicated, highly customized, and elaborate merchandising implementation. That’s so old school. Quantic brings you simplicity and can implement your merchandising and planning suite for a fraction of the time and cost.</p>


      <div>Demand innovation.</div>
      <p>Quantic’s merchandising consultants challenge the status quo bringing you cutting edge innovation in the world of merchandising.</p>

      <div>TECHNOLOGY EXPERTISE</div>
      <div class="col-sm-6 text-left">
        <ul>
          <li>Retail Merchandising System (RMS)</li>
          <li>Retail Price Management (RPM)</li>
          <li>Retail Sales Audit (ReSA)</li>
          <li>Retail Allocation</li>
          <li>Retail Invoice Matching (ReIM)</li>
          <li>Retail Merchandising Analytics (ORMA)</li>
          <li>Retail Trade Management</li>
          <li>Retail Integration Bus (RIB)</li>
          <li>Retail Merchandise Financial Planning (MFP)</li>
          <li>Retail Assortment Planning</li>
          <li>Retail Demand Forecasting (RDF)</li>
          <li>Retail Category Management</li>
          <li>Retail Store Inventory Management (SIM)</li>
          <li>Retail Advanced Inventory Planning (AIP)</li>
          <li>Retail Replenishment Optimization (RO)</li>
          <li>Retail Customer Insights Cloud Service</li>
        </ul>
      </div>
    </section>
  </Layout>
);

export default Page;
